"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateManagementHttpClient = void 0;
class TemplateManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  create(templateCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/templates`;
      const response = yield this.httpClient.post(url, {
        type: templateCreateRequest.type,
        templateName: templateCreateRequest.templateName,
        content: templateCreateRequest.content,
        locale: templateCreateRequest.locale,
        placeholders: templateCreateRequest.placeholders
      });
      return response;
    });
  }
  update(templateUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/templates/${templateUpdateRequest.templateId}`;
      const response = yield this.httpClient.put(url, {
        type: templateUpdateRequest.type,
        content: templateUpdateRequest.content,
        locale: templateUpdateRequest.locale,
        placeholders: templateUpdateRequest.placeholders
      });
      return response;
    });
  }
  delete(templateDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/templates/${templateDeleteRequest.templateId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  get(templateGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/templates/${templateGetRequest.templateId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  list(templateListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/templates`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
}
exports.TemplateManagementHttpClient = TemplateManagementHttpClient;