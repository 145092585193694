"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentUserManagementHttpClient = void 0;
class ConsentUserManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  create(consentUserCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/consents`;
      const response = yield this.httpClient.post(url, {
        consentUser: consentUserCreateRequest.consentUser
      });
      return response;
    });
  }
  get(consentUserGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/consents/users/${consentUserGetRequest.userId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  list(consentUserListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/consents/users`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
}
exports.ConsentUserManagementHttpClient = ConsentUserManagementHttpClient;