"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileManager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class FileManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  // create/replace file
  create(FileCreateReplaceRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!FileCreateReplaceRequest.isValid()) {
        throw new error_1.InvalidParameterException(FileManager.name, 'Missing one or more of the required parameters in FileCreateReplaceRequest.');
      }
      this.addAuth();
      return yield this.client.create(FileCreateReplaceRequest);
    });
  }
  // Discard file
  discard() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.client.discardUpload();
      return true;
    });
  }
  // Create Folder
  createFolder(folderCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!folderCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(FileManager.name, 'Invalid required parameters in request.');
      }
      this.addAuth();
      return yield this.client.createFolder(folderCreateRequest);
    });
  }
  // Move File
  move(fileMoveRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!fileMoveRequest.isValid()) {
        throw new error_1.InvalidParameterException(FileManager.name, 'Invalid required parameters in request.');
      }
      this.addAuth();
      return yield this.client.move(fileMoveRequest);
    });
  }
  // Delete file or folder
  delete(deleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!deleteRequest.isValid()) {
        throw new error_1.InvalidParameterException(FileManager.name, 'Invalid required parameters in request.');
      }
      this.addAuth();
      return yield this.client.delete(deleteRequest);
    });
  }
  // create file
  createFiles(FilesCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!FilesCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(FileManager.name, 'Missing one or more of the required parameters in FileCreateRequest.');
      }
      this.addAuth();
      return yield this.client.createFiles(FilesCreateRequest);
    });
  }
  // Get File 
  get(fileGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!fileGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(FileManager.name, 'Missing one or more of the required parameters in FileGetRequest.');
      }
      this.addAuth();
      return yield this.client.get(fileGetRequest);
    });
  }
  // List Files 
  list(fileListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      this.addAuth();
      return yield this.client.list(fileListRequest);
    });
  }
  addAuth() {
    const authToken = this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
    if (authToken === null) throw new error_1.UnauthorizedException(FileManager.name, 'Operation requires being authenticated.');
    this.client.httpClient.addAuthentication(authToken);
  }
}
exports.FileManager = FileManager;