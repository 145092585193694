"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentRequestPlatform = exports.PaymentListMethodsRequest = void 0;
const request_1 = require("../request/request");
class PaymentListMethodsRequest extends request_1.Request {
  constructor(currency, amount, countryCode, userLocale, platform) {
    super();
    this.currency = currency;
    this.amount = amount;
    this.countryCode = countryCode;
    this.userLocale = userLocale;
    this.platform = platform;
  }
  isValid() {
    if (this.currency === '') {
      return false;
    }
    if (this.countryCode === '') {
      return false;
    }
    if (this.userLocale === '') {
      return false;
    }
    if (this.amount < 0) {
      return false;
    }
    return true;
  }
}
exports.PaymentListMethodsRequest = PaymentListMethodsRequest;
var PaymentRequestPlatform;
(function (PaymentRequestPlatform) {
  PaymentRequestPlatform["Apple"] = "IOs";
  PaymentRequestPlatform["Android"] = "Android";
  PaymentRequestPlatform["Web"] = "Web";
})(PaymentRequestPlatform = exports.PaymentRequestPlatform || (exports.PaymentRequestPlatform = {}));