import { Injectable } from '@angular/core';
import { Store } from '@ct-ibd/cmn-ibd-store-typescript-sdk';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements Store<string> {
  localStore = localStorage;
  constructor() {}
  add(key: string, value: string): boolean {
    this.localStore.setItem(key, value);
    return true;
  }

  get(key: string): string {
    const x = this.localStore.getItem(key);
    return x || '';
  }

  del(key: string): boolean {
    this.localStore.removeItem(key);
    return true;
  }

  isValid(key: string): boolean {
    return this.localStore.getItem(key) ? true : false;
  }

  setCollection(name: string): void {}
}
