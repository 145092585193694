"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Configuration = void 0;
const configuration_data_and_types_1 = require("./configuration_data_and_types");
const error_1 = require("../errors/error");
class Configuration {
  constructor(appClientId, environment) {
    this.baseConfigurationData = {
      appClientId: appClientId,
      environment: environment
    };
  }
  load() {
    let returnData;
    switch (this.baseConfigurationData.environment) {
      case 'dev':
        returnData = Object.assign(Object.assign({}, this.baseConfigurationData), configuration_data_and_types_1.ConfigurationData.dev);
        break;
      case 'stage':
        returnData = Object.assign(Object.assign({}, this.baseConfigurationData), configuration_data_and_types_1.ConfigurationData.stage);
        break;
      case 'sandbox':
        returnData = Object.assign(Object.assign({}, this.baseConfigurationData), configuration_data_and_types_1.ConfigurationData.sandbox);
        break;
      case 'prod':
        returnData = Object.assign(Object.assign({}, this.baseConfigurationData), configuration_data_and_types_1.ConfigurationData.prod);
        break;
      default:
        throw new error_1.InvalidEnvironmentConfigurationError(Configuration.name, 'Incorrect environment passed.');
    }
    return returnData;
  }
}
exports.Configuration = Configuration;