"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileCreateReplaceRequest = void 0;
const request_1 = require("../request/request");
// Create File request
class FileCreateReplaceRequest extends request_1.Request {
  constructor(file, publicFile, replace, path, owner) {
    super();
    this.file = file;
    this.public = publicFile;
    this.replace = replace;
    this.path = path;
    this.owner = owner; //quick fix because, cmn authorizer function logic is not working as expected
  }
  isValid() {
    if (!this.file) {
      return false;
    }
    let allowedExt = ['file/xls', 'file/pdf', 'file/txt', 'file/doc', 'file/docx', 'file/xlsx', 'file/ppt', 'file/csv', 'file/json', 'file/zip', 'file/stp', 'file/bin'];
    if (!allowedExt.includes(this.file.type)) {
      return false;
    }
    if (this.path !== undefined && this.path.trim() === '') {
      return false;
    }
    return true;
  }
}
exports.FileCreateReplaceRequest = FileCreateReplaceRequest;