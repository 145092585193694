"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileDeleteRequest = void 0;
const request_1 = require("../request/request");
// Delete request
class FileDeleteRequest extends request_1.Request {
  constructor(fileId) {
    super();
    this.fileId = fileId;
  }
  isValid() {
    if (!this.fileId) {
      return false;
    }
    return true;
  }
}
exports.FileDeleteRequest = FileDeleteRequest;