"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserSetPasswordRequest = void 0;
const request_1 = require("../request/request");
class UserSetPasswordRequest extends request_1.Request {
  constructor(email, secret, password) {
    super();
    this.email = email;
    this.secret = secret;
    this.password = password;
  }
  isValid() {
    if (this.email === '') {
      return false;
    }
    if (typeof this.secret !== 'number') {
      return false;
    }
    if (this.password === '') {
      return false;
    }
    return true;
  }
}
exports.UserSetPasswordRequest = UserSetPasswordRequest;