"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentOptionUpdateRequest = void 0;
const request_1 = require("../request/request");
class ConsentOptionUpdateRequest extends request_1.Request {
  constructor(optionId, optionName, content, categories, localesAvailable, defaultLocale, isSystemCritical, version) {
    super();
    this.content = content || {};
    this.optionName = optionName;
    this.categories = categories || [];
    this.localesAvailable = localesAvailable || ["de_DE"];
    this.defaultLocale = defaultLocale || "de_DE";
    this.isSystemCritical = isSystemCritical || false;
    this.optionId = optionId;
    this.version = version;
  }
  isValid() {
    if (this.optionName === '' || this.optionId === '' || this.content === {}) {
      return false;
    }
    return true;
  }
}
exports.ConsentOptionUpdateRequest = ConsentOptionUpdateRequest;