"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationManagerHttpClient = void 0;
class AuthenticationManagerHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  userLogin(userLoginData) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/user-login`;
      let requestData = {
        email: userLoginData.email,
        username: userLoginData.username,
        password: userLoginData.password
      };
      if (requestData.email === '') delete requestData.email;
      if (requestData.username === '') delete requestData.username;
      const response = yield this.httpClient.post(url, requestData);
      return {
        accessToken: response.accessToken || '',
        refreshToken: response.refreshToken || '',
        idToken: response.idToken || '',
        userPoolId: response.userPoolId || ''
      };
    });
  }
  logoutUser(logoutUserRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/user-logout`;
      const response = yield this.httpClient.post(url, {});
      return response;
    });
  }
  logoutUserDevice(logoutUserDeviceRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/user-logout-device`;
      const response = yield this.httpClient.post(url, {
        refreshToken: logoutUserDeviceRequest.refreshToken
      });
      return response;
    });
  }
  refreshUserToken(refreshUserTokenRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/refresh-user-token`;
      const response = yield this.httpClient.post(url, {
        refreshToken: refreshUserTokenRequest.refreshToken,
        email: refreshUserTokenRequest.email
      });
      return response;
    });
  }
}
exports.AuthenticationManagerHttpClient = AuthenticationManagerHttpClient;