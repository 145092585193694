"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateInstructionRequest = void 0;
const request_1 = require("../request/request");
class UpdateInstructionRequest extends request_1.Request {
  constructor(editId, instructionId, instruction) {
    super();
    this.editId = editId;
    this.instructionId = instructionId;
    this.instruction = instruction;
  }
  isValid() {
    if (Object.keys(this.instruction).length == 0) {
      return false;
    }
    if (!this.instructionId) {
      return false;
    }
    if (!this.editId) {
      return false;
    }
    return true;
  }
}
exports.UpdateInstructionRequest = UpdateInstructionRequest;