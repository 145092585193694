"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommunicationCreateRequest = void 0;
const request_1 = require("../request/request");
class CommunicationCreateRequest extends request_1.Request {
  constructor(eventName, channel, payload, eventType, messageId, eventCategory, targetId) {
    super();
    this.payload = payload;
    this.targetId = targetId;
    this.channel = channel;
    this.eventCategory = eventCategory;
    this.messageId = messageId;
    this.eventType = eventType;
    this.eventName = eventName;
  }
  isValid() {
    if (this.channel === '' || this.eventName === '') {
      return false;
    }
    return true;
  }
}
exports.CommunicationCreateRequest = CommunicationCreateRequest;