"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RoleManager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class RoleManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  // Create role manager
  createRole(roleCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!roleCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in RoleCreateRequest.');
      }
      this.addAuth();
      return yield this.client.createRole(roleCreateRequest);
    });
  }
  // Create permission manager
  createPermission(permissionCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!permissionCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in PermissionCreateRequest.');
      }
      this.addAuth();
      return yield this.client.createPermission(permissionCreateRequest);
    });
  }
  // get permission manager
  updatePermission(permissionUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!permissionUpdateRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in PermissionUpdateRequest.');
      }
      this.addAuth();
      return yield this.client.updatePermission(permissionUpdateRequest);
    });
  }
  // Get permission manager
  getPermission(permissionGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!permissionGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in PermissionGetRequest.');
      }
      this.addAuth();
      return yield this.client.getPermission(permissionGetRequest);
    });
  }
  // Add Permission for a role manager on basis of role Id
  addRolePermission(addRolePermissionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!addRolePermissionRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in AddRolePermissionRequest.');
      }
      this.addAuth();
      return yield this.client.addRolePermission(addRolePermissionRequest);
    });
  }
  // Get role on basis of role Id
  getRole(roleGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!roleGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in RoleGetRequest.');
      }
      this.addAuth();
      return yield this.client.getRole(roleGetRequest);
    });
  }
  // List roles 
  listRole(roleGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!roleGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(RoleManager.name, 'Missing one or more of the required parameters in RoleListRequest.');
      }
      this.addAuth();
      return yield this.client.listRole(roleGetRequest);
    });
  }
  // validate authentication
  addAuth() {
    const authToken = this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
    if (authToken === null) throw new error_1.UnauthorizedException(RoleManager.name, 'Operation requires being authenticated.');
    this.client.httpClient.addAuthentication(authToken);
  }
}
exports.RoleManager = RoleManager;