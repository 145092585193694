"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadCompleteException = exports.UploadPartException = exports.UploadInitException = exports.InvalidEnvironmentConfigurationError = exports.OperationException = exports.UnauthorizedException = exports.ResponseException = exports.InvalidJsonContentInFileException = exports.InvalidFilePathException = exports.InvalidPropertyException = exports.InvalidParameterException = void 0;
class BaseException extends Error {
  constructor(thrower, message) {
    super(`$thrower threw: \n$message`);
    this.thrower = thrower;
    this.message = message;
  }
}
class InvalidParameterException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.InvalidParameterException = InvalidParameterException;
class InvalidPropertyException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.InvalidPropertyException = InvalidPropertyException;
class InvalidFilePathException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.InvalidFilePathException = InvalidFilePathException;
class InvalidJsonContentInFileException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.InvalidJsonContentInFileException = InvalidJsonContentInFileException;
class ResponseException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.ResponseException = ResponseException;
class UnauthorizedException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.UnauthorizedException = UnauthorizedException;
class OperationException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.OperationException = OperationException;
class InvalidEnvironmentConfigurationError extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.InvalidEnvironmentConfigurationError = InvalidEnvironmentConfigurationError;
class UploadInitException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.UploadInitException = UploadInitException;
class UploadPartException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.UploadPartException = UploadPartException;
class UploadCompleteException extends BaseException {
  constructor(thrower, message) {
    super(thrower, message);
  }
}
exports.UploadCompleteException = UploadCompleteException;