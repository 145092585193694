"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserManagementHttpClient = void 0;
class UserManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  create(userCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users`;
      const response = yield this.httpClient.post(url, {
        'email': userCreateRequest.email,
        'username': userCreateRequest.username,
        'phone': userCreateRequest.phoneNumber,
        'password': userCreateRequest.password,
        'locale': userCreateRequest.locale,
        'templatePayload': userCreateRequest.templatePayload
      });
      return response;
    });
  }
  update(userUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/${userUpdateRequest.userId}`;
      const response = yield this.httpClient.put(url, {
        email: userUpdateRequest.email,
        phone: userUpdateRequest.phone,
        enable: userUpdateRequest.enabled,
        templatePayload: userUpdateRequest.templatePayload
      });
      return response;
    });
  }
  resetPassword(userResetPasswordRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/reset-password`;
      const response = yield this.httpClient.post(url, {
        email: userResetPasswordRequest.email,
        codeAs: userResetPasswordRequest.codeAs,
        templatePayload: userResetPasswordRequest.templatePayload
      });
      return response;
    });
  }
  verify(userVerifyRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/verify`;
      const response = yield this.httpClient.post(url, {
        'email': userVerifyRequest.email,
        'secret': userVerifyRequest.secret,
        'templatePayload': userVerifyRequest.templatePayload
      });
      return response;
    });
  }
  delete(userDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      let url = `${this.baseUrl}/users/${userDeleteRequest.userId}`;
      const response = yield this.httpClient.delete(url, {
        'templatePayload': userDeleteRequest.templatePayload
      });
      return response;
    });
  }
  setPassword(userSetPasswordRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/set-password`;
      const response = yield this.httpClient.post(url, {
        'email': userSetPasswordRequest.email,
        'secret': userSetPasswordRequest.secret,
        'password': userSetPasswordRequest.password
      });
      return response;
    });
  }
  changePassword(userChangePasswordRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/${userChangePasswordRequest.userId}/change-password`;
      const response = yield this.httpClient.post(url, {
        'oldPassword': userChangePasswordRequest.oldPassword,
        'newPassword': userChangePasswordRequest.newPassword
      });
      return response;
    });
  }
  addUserRole(userAddRoleRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/${userAddRoleRequest.userId}/roles`;
      const response = yield this.httpClient.post(url, {
        'roleIds': userAddRoleRequest.roleIds
      });
      return response;
    });
  }
  updateUserRole(userUpdateRoleRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/${userUpdateRoleRequest.userId}/roles`;
      const response = yield this.httpClient.put(url, {
        'roleIds': userUpdateRoleRequest.roleIds
      });
      return response;
    });
  }
  deleteUserRole(userDeleteRoleRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/${userDeleteRoleRequest.userId}/roles`;
      const response = yield this.httpClient.delete(url, {
        data: {
          'roleIds': userDeleteRoleRequest.roleIds
        }
      });
      return response;
    });
  }
  list(userListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users`;
      const response = yield this.httpClient.get(url, userListRequest.filter);
      return response;
    });
  }
  createClientApplication(clientApplicationCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/client-applications`;
      const response = yield this.httpClient.post(url, {
        'clientName': clientApplicationCreateRequest.clientName
      });
      return response;
    });
  }
  deleteClientApplication(clientApplicationDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/client-applications`;
      const response = yield this.httpClient.delete(`${url}/${clientApplicationDeleteRequest.clientApplicationId}`);
      return response;
    });
  }
  getClientApplication(clientApplicationGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/client-applications/${clientApplicationGetRequest.clientApplicationId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  listClientApplication(clientApplicationListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/client-applications`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  createBulkUsers(userCreateBulkRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/bulk`;
      const response = yield this.httpClient.post(url, {
        users: userCreateBulkRequest.users,
        templatePayload: userCreateBulkRequest.templatePayload
      });
      return response;
    });
  }
  resendVerification(userResendVerificationRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/resend-verification`;
      const response = yield this.httpClient.post(url, {
        email: userResendVerificationRequest.email,
        templatePayload: userResendVerificationRequest.templatePayload
      });
      return response;
    });
  }
  adminCreate(userAdminCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/admin`;
      const response = yield this.httpClient.post(url, {
        email: userAdminCreateRequest.email,
        username: userAdminCreateRequest.username,
        phone: userAdminCreateRequest.phoneNumber,
        password: userAdminCreateRequest.password,
        locale: userAdminCreateRequest.locale,
        admin: userAdminCreateRequest.isAdmin,
        templatePayload: userAdminCreateRequest.templatePayload
      });
      return response;
    });
  }
  analyzeBulkUsers(userAnalyzeBulkRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/bulk-analyze`;
      const response = yield this.httpClient.post(url, {
        users: userAnalyzeBulkRequest.users
      });
      return response;
    });
  }
  fetchUsers(userFetchRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/users/fetch`;
      const response = yield this.httpClient.post(url, {
        userIds: userFetchRequest.userIds
      });
      return response;
    });
  }
}
exports.UserManagementHttpClient = UserManagementHttpClient;