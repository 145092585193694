"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentUserCreateRequest = void 0;
const request_1 = require("../request/request");
class ConsentUserCreateRequest extends request_1.Request {
  constructor(consentUser) {
    super();
    this.consentUser = consentUser;
  }
  isValid() {
    if (this.consentUser.length <= 0) {
      return false;
    }
    return true;
  }
}
exports.ConsentUserCreateRequest = ConsentUserCreateRequest;