"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PermissionUpdateRequest = void 0;
const request_1 = require("../request/request");
// Update permission on basis of permission id request
class PermissionUpdateRequest extends request_1.Request {
  constructor(permissionId, permissionName, url, method) {
    super();
    this.permissionId = permissionId;
    this.permissionName = permissionName;
    this.url = url;
    this.method = method;
  }
  isValid() {
    if (this.permissionId === '') {
      return false;
    }
    if (this.permissionName === '') {
      return false;
    }
    if (this.url === '') {
      return false;
    }
    if (this.method === '') {
      return false;
    }
    return true;
  }
}
exports.PermissionUpdateRequest = PermissionUpdateRequest;