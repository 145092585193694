"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InitiateEditRequest = void 0;
const request_1 = require("../request/request");
class InitiateEditRequest extends request_1.Request {
  constructor(videoId, originalVideoDuration, dimension, name = '') {
    super();
    this.videoId = videoId;
    this.originalVideoDuration = originalVideoDuration;
    this.dimension = dimension;
    this.name = name;
  }
  isValid() {
    if (!this.videoId) {
      return false;
    }
    if (!this.originalVideoDuration) {
      return false;
    }
    if (!this.dimension || !this.dimension.height || !this.dimension.width) {
      return false;
    }
    return true;
  }
}
exports.InitiateEditRequest = InitiateEditRequest;