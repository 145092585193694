"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateCreateRequest = void 0;
const request_1 = require("../request/request");
class TemplateCreateRequest extends request_1.Request {
  constructor(templateName, type, placeholders, locale, content) {
    super();
    this.templateName = templateName;
    this.type = type;
    this.placeholders = placeholders;
    this.locale = locale, this.content = content;
  }
  isValid() {
    if (this.templateName === '') {
      return false;
    }
    if (this.type === '') {
      return false;
    }
    if (this.locale === '') {
      return false;
    }
    if (this.content === '') {
      return false;
    }
    return true;
  }
}
exports.TemplateCreateRequest = TemplateCreateRequest;