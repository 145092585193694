"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserBulkAnalyzeRequest = void 0;
const request_1 = require("../request/request");
class UserBulkAnalyzeRequest extends request_1.Request {
  constructor(users) {
    super();
    this.users = users;
  }
  isValid() {
    if (this.users.length === 0) {
      return false;
    }
    return true;
  }
}
exports.UserBulkAnalyzeRequest = UserBulkAnalyzeRequest;