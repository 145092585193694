"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserResendVerificationRequest = void 0;
const request_1 = require("../request/request");
class UserResendVerificationRequest extends request_1.Request {
  constructor(email, templatePayload) {
    super();
    this.email = email;
    this.templatePayload = templatePayload;
  }
  isValid() {
    if (this.email === '') {
      return false;
    }
    return true;
  }
}
exports.UserResendVerificationRequest = UserResendVerificationRequest;