"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoManagementHttpClient = void 0;
const error_1 = require("../errors/error");
const httpUpload_1 = require("../http/httpUpload");
class VideoManagementHttpClient {
  constructor(http, baseUrl, uploadParams) {
    this.httpClient = http;
    this.httpUploadClient = null;
    this.baseUrl = baseUrl;
    this.tempCredentials = {};
    this.uploadParams = uploadParams;
  }
  // Upload video
  upload(videoUploadRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.httpUploadClient) {
        throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, 'Upload already in progress');
      }
      let uploadConfig = {
        credentialUrl: this.baseUrl + '/videos',
        credentialBody: {
          fileName: videoUploadRequest.file.name,
          fileType: videoUploadRequest.file.name.substr(videoUploadRequest.file.name.lastIndexOf('.') + 1),
          fileSize: videoUploadRequest.file.size / 1000,
          public: videoUploadRequest.public
        },
        file: videoUploadRequest.file,
        params: this.uploadParams
      };
      this.httpUploadClient = new httpUpload_1.HttpUploadClient(this.httpClient, uploadConfig);
      let uploadResponse = yield this.httpUploadClient.upload();
      let response = {
        videoId: uploadResponse.videoId,
        key: uploadResponse.key,
        url: uploadResponse.url,
        createdDate: uploadResponse.createdDate,
        updatedDate: uploadResponse.updatedDate
      };
      this.httpUploadClient = null;
      return response;
    });
  }
  discardUpload() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.httpUploadClient) {
        throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, 'No upload is in process');
      }
      return yield this.httpUploadClient.discardUpload();
    });
  }
  delete(videoDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/videos/${videoDeleteRequest.videoId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  getVideoDetails(getVideoRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/videos/${getVideoRequest.videoId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
}
exports.VideoManagementHttpClient = VideoManagementHttpClient;