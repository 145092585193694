"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentCreateRequest = void 0;
const request_1 = require("../request/request");
class DocumentCreateRequest extends request_1.Request {
  constructor(content, locale, name) {
    super();
    this.content = content;
    this.locale = locale;
    this.name = name;
  }
  isValid() {
    if (Object.keys(this.content).length == 0) {
      return false;
    }
    if (this.locale === '') {
      return false;
    }
    if (this.name === '') {
      return false;
    }
    return true;
  }
}
exports.DocumentCreateRequest = DocumentCreateRequest;