"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InMemoryStore = void 0;
const NodeCache = require("node-cache");
class InMemoryStore {
  constructor(ttl = 0, checkperiod = 600, useClones = true) {
    this.localStore = new NodeCache({
      stdTTL: ttl,
      checkperiod: checkperiod,
      useClones: useClones
    });
  }
  add(key, value) {
    this.localStore.set(key, value);
  }
  get(key) {
    return this.localStore.get(key);
  }
  del(key) {
    this.localStore.del(key);
    return true;
  }
  setCollection(collection) {}
}
exports.InMemoryStore = InMemoryStore;