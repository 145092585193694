"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FinishEditRequest = void 0;
const request_1 = require("../request/request");
class FinishEditRequest extends request_1.Request {
  constructor(editId, name) {
    super();
    this.editId = editId;
    this.name = name;
  }
  isValid() {
    if (!this.editId) {
      return false;
    }
    return true;
  }
}
exports.FinishEditRequest = FinishEditRequest;