"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageGetRequest = void 0;
const request_1 = require("../request/request");
// Get Image request 
class ImageGetRequest extends request_1.Request {
  constructor(imageId) {
    super();
    this.imageId = imageId;
  }
  isValid() {
    if (!this.imageId) {
      return false;
    }
    return true;
  }
}
exports.ImageGetRequest = ImageGetRequest;