"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentOptionManager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class ConsentOptionManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  update(consentOptionUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!consentOptionUpdateRequest.isValid()) {
        throw new error_1.InvalidParameterException(ConsentOptionManager.name, 'Missing one or more of the required parameters in ConsentOptionUpdateRequest.');
      }
      this.addAuth();
      return yield this.client.update(consentOptionUpdateRequest);
    });
  }
  create(consentOptionCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!consentOptionCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(ConsentOptionManager.name, 'Missing one or more of the required parameters in ConsentOptionCreateRequest.');
      }
      return yield this.client.create(consentOptionCreateRequest);
    });
  }
  delete(consentOptionDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!consentOptionDeleteRequest.isValid()) {
        throw new error_1.InvalidParameterException(ConsentOptionManager.name, 'Missing one or more of the required parameters in ConsentOptionDeleteRequests.');
      }
      this.addAuth();
      return yield this.client.delete(consentOptionDeleteRequest);
    });
  }
  get(consentOptionGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!consentOptionGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(ConsentOptionManager.name, 'Missing one or more of the required parameters in ConsentOptionGetRequest.');
      }
      this.addAuth();
      return yield this.client.get(consentOptionGetRequest);
    });
  }
  list(consentOptionListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!consentOptionListRequest.isValid()) {
        throw new error_1.InvalidParameterException(ConsentOptionManager.name, 'Missing one or more of the required parameters in ConsentOptionListRequest.');
      }
      this.addAuth();
      return yield this.client.list(consentOptionListRequest);
    });
  }
  addAuth() {
    const authToken = this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
    if (authToken === null) throw new error_1.UnauthorizedException(ConsentOptionManager.name, 'Operation requires being authenticated.');
    this.client.httpClient.addAuthentication(authToken);
  }
}
exports.ConsentOptionManager = ConsentOptionManager;