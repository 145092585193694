"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentOptionCreateRequest = void 0;
const request_1 = require("../request/request");
class ConsentOptionCreateRequest extends request_1.Request {
  constructor(content, optionName, categories, localesAvailable, defaultLocale, isSystemCritical) {
    super();
    this.content = content;
    this.optionName = optionName;
    this.categories = categories;
    this.localesAvailable = localesAvailable || ["de_DE"];
    this.defaultLocale = defaultLocale || "de_DE";
    this.isSystemCritical = isSystemCritical || false;
  }
  isValid() {
    if (this.content === {} || this.optionName === '' || this.categories.length <= 0) {
      return false;
    }
    return true;
  }
}
exports.ConsentOptionCreateRequest = ConsentOptionCreateRequest;