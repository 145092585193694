"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserUpdateRequest = void 0;
const request_1 = require("../request/request");
class UserUpdateRequest extends request_1.Request {
  constructor(userId, email, phone, enabled, templatePayload) {
    super();
    this.userId = userId;
    this.email = email;
    this.phone = phone;
    this.enabled = enabled;
    this.templatePayload = templatePayload;
  }
  isValid() {
    if (this.userId === '') {
      return false;
    }
    if (this.email === '') {
      return false;
    }
    if (this.phone === '') {
      return false;
    }
    return true;
  }
}
exports.UserUpdateRequest = UserUpdateRequest;