"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Ibd = void 0;
const authentication_manager_1 = require("./authentication/authentication_manager");
const authentication_manager_http_client_1 = require("./authentication/authentication_manager_http_client");
const email_management_http_client_1 = require("./email/email_management_http_client");
const email_manager_1 = require("./email/email_manager");
const http_1 = require("./http/http");
const image_management_http_client_1 = require("./image/image_management_http_client");
const image_manager_1 = require("./image/image_manager");
const profile_management_http_client_1 = require("./profile/profile_management_http_client");
const profile_manager_1 = require("./profile/profile_manager");
const role_management_http_client_1 = require("./role/role_management_http_client");
const role_manager_1 = require("./role/role_manager");
const user_management_http_client_1 = require("./user/user_management_http_client");
const user_manager_1 = require("./user/user_manager");
const video_management_http_client_1 = require("./video/video_management_http_client");
const video_manager_1 = require("./video/video_manager");
const push_notification_manager_1 = require("./push-notification/push_notification_manager");
const push_notification_management_http_client_1 = require("./push-notification/push_notification_management_http_client");
const file_manager_1 = require("./file/file_manager");
const file_management_http_client_1 = require("./file/file_management_http_client");
const document_manager_1 = require("./legal-document/document_manager");
const document_management_http_client_1 = require("./legal-document/document_management_http_client");
const consent_option_manager_1 = require("./consent-option/consent_option_manager");
const consent_option_management_http_client_1 = require("./consent-option/consent_option_management_http_client");
const consent_user_manager_1 = require("./consent-user/consent_user_manager");
const consent_user_management_http_client_1 = require("./consent-user/consent_user_management_http_client");
const payment_manager_1 = require("./payment/payment_manager");
const payment_management_http_client_1 = require("./payment/payment_management_http_client");
const token_manager_1 = require("./token/token_manager");
const token_management_http_client_1 = require("./token/token_management_http_client");
const template_management_http_client_1 = require("./template/template_management_http_client");
const template_manager_1 = require("./template/template_manager");
const communication_management_http_client_1 = require("./communication/communication_management_http_client");
const communication_manager_1 = require("./communication/communication_manager");
const video_rendering_manager_1 = require("./video-rendering/video_rendering_manager");
const video_rendering_management_http_client_1 = require("./video-rendering/video_rendering_management_http_client");
class Ibd {
  constructor(store, config) {
    this.store = store;
    this.config = config;
    this.http = new http_1.HttpClient(this.config.appClientId);
    this.authenticationManager = this.loadAuthenticationManager();
    this.userManager = this.loadUserManager();
    this.profileManager = this.loadProfileManager();
    this.roleManager = this.loadRoleManager();
    this.emailManager = this.loadEmailManager();
    this.imageManager = this.loadImageManager();
    this.videoManager = this.loadVideoManager();
    this.pushNotificationManager = this.loadPushNotificationManager();
    this.fileManager = this.loadFileManager();
    this.documentManager = this.loadDocumentManager();
    this.consentOptionManager = this.loadConsentOptionManager();
    this.consentUserManager = this.loadConsentUserManager();
    this.paymentManager = this.loadPaymentManager();
    this.tokenManager = this.loadTokenManager();
    this.templateManager = this.loadTemplateManager();
    this.communicationManager = this.loadCommunicationManager();
    this.videoRenderingManager = this.loadVideoRenderingManager();
  }
  loadAuthenticationManager() {
    const client = new authentication_manager_http_client_1.AuthenticationManagerHttpClient(this.http, this.config.authenticationManagementServiceUrl);
    return new authentication_manager_1.AuthenticationManager(this.store, client, this.config);
  }
  loadUserManager() {
    const client = new user_management_http_client_1.UserManagementHttpClient(this.http, this.config.userManagementServiceUrl);
    return new user_manager_1.UserManager(this.store, client, this.config);
  }
  loadProfileManager() {
    const client = new profile_management_http_client_1.ProfileManagementHttpClient(this.http, this.config.profileManagementServiceUrl);
    return new profile_manager_1.ProfileManager(this.store, client, this.config);
  }
  loadRoleManager() {
    const client = new role_management_http_client_1.RoleManagementHttpClient(this.http, this.config.roleManagementServiceUrl);
    return new role_manager_1.RoleManager(this.store, client, this.config);
  }
  loadEmailManager() {
    const client = new email_management_http_client_1.EmailManagementHttpClient(this.http, this.config.emailManagementServiceUrl);
    return new email_manager_1.EmailManager(this.store, client, this.config);
  }
  loadImageManager() {
    const client = new image_management_http_client_1.ImageManagementHttpClient(this.http, this.config.imageManagementServiceUrl, this.config.imageUploadConfig);
    return new image_manager_1.ImageManager(this.store, client, this.config);
  }
  loadVideoManager() {
    const client = new video_management_http_client_1.VideoManagementHttpClient(this.http, this.config.videoManagementServiceUrl, this.config.videoUploadConfig);
    return new video_manager_1.VideoManager(this.store, client, this.config);
  }
  loadPushNotificationManager() {
    const client = new push_notification_management_http_client_1.PushNotificationManagementHttpClient(this.http, this.config.pushNotificationManagementServiceUrl);
    return new push_notification_manager_1.PushNotificationManager(this.store, client, this.config);
  }
  loadFileManager() {
    const client = new file_management_http_client_1.FileManagementHttpClient(this.http, this.config.fileManagementServiceUrl, this.config.fileUploadConfig);
    return new file_manager_1.FileManager(this.store, client, this.config);
  }
  loadDocumentManager() {
    const client = new document_management_http_client_1.DocumentManagementHttpClient(this.http, this.config.legalDocumentServiceUrl);
    return new document_manager_1.DocumentManager(this.store, client, this.config);
  }
  loadConsentOptionManager() {
    const client = new consent_option_management_http_client_1.ConsentOptionManagementHttpClient(this.http, this.config.consentOptionManagementServiceUrl);
    return new consent_option_manager_1.ConsentOptionManager(this.store, client, this.config);
  }
  loadConsentUserManager() {
    const client = new consent_user_management_http_client_1.ConsentUserManagementHttpClient(this.http, this.config.consentUserManagementServiceUrl);
    return new consent_user_manager_1.ConsentUserManager(this.store, client, this.config);
  }
  loadPaymentManager() {
    const client = new payment_management_http_client_1.PaymentManagementHttpClient(this.http, this.config.paymentManagementServiceUrl);
    return new payment_manager_1.PaymentManager(this.store, client, this.config);
  }
  loadTokenManager() {
    const client = new token_management_http_client_1.TokenManagementHttpClient(this.http, this.config.tokenManagementServiceUrl);
    return new token_manager_1.TokenManager(this.store, client, this.config);
  }
  loadTemplateManager() {
    const client = new template_management_http_client_1.TemplateManagementHttpClient(this.http, this.config.templateManagementServiceUrl);
    return new template_manager_1.TemplateManager(this.store, client, this.config);
  }
  loadCommunicationManager() {
    const client = new communication_management_http_client_1.CommunicationManagementHttpClient(this.http, this.config.communicationManagementServiceUrl);
    return new communication_manager_1.CommunicationManager(this.store, client, this.config);
  }
  loadVideoRenderingManager() {
    const client = new video_rendering_management_http_client_1.VideoRenderingManagementHttpClient(this.http, this.config.videoRenderingManagementServiceUrl);
    return new video_rendering_manager_1.VideoRenderingManager(this.store, client, this.config);
  }
}
exports.Ibd = Ibd;