"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmailManagementHttpClient = void 0;
class EmailManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  // Send email
  send(emailSendRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/emails`;
      const response = yield this.httpClient.post(url, {
        recipient: emailSendRequest.recipient,
        sender: emailSendRequest.sender,
        subject: emailSendRequest.subject,
        content: emailSendRequest.content,
        contentType: emailSendRequest.contentType,
        attachments: emailSendRequest.attachments
      });
      return {
        emailId: response.emailId,
        createdDate: response.createdDate,
        updatedDate: response.updatedDate
      };
    });
  }
}
exports.EmailManagementHttpClient = EmailManagementHttpClient;