"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticateUserRequest = void 0;
const request_1 = require("../request/request");
class AuthenticateUserRequest extends request_1.Request {
  constructor(request) {
    super();
    this.username = request.username || '';
    this.email = request.email || '';
    this.password = request.password;
  }
  isValid() {
    if (this.email === '' && this.username === '') {
      return false;
    }
    if (this.password === '') {
      return false;
    }
    return true;
  }
}
exports.AuthenticateUserRequest = AuthenticateUserRequest;