"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PermissionGetRequest = void 0;
const request_1 = require("../request/request");
// Get permission request on basis of permission Id
class PermissionGetRequest extends request_1.Request {
  constructor(permissionId) {
    super();
    this.permissionId = permissionId;
  }
  isValid() {
    if (this.permissionId === '') {
      return false;
    }
    return true;
  }
}
exports.PermissionGetRequest = PermissionGetRequest;