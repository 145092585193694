"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteInstructionRequest = void 0;
const request_1 = require("../request/request");
class DeleteInstructionRequest extends request_1.Request {
  constructor(editId, instructionId) {
    super();
    this.editId = editId;
    this.instructionId = instructionId;
  }
  isValid() {
    if (!this.editId) {
      return false;
    }
    if (!this.instructionId) {
      return false;
    }
    return true;
  }
}
exports.DeleteInstructionRequest = DeleteInstructionRequest;