"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddRolePermissionRequest = void 0;
const request_1 = require("../request/request");
// Add permission to role
class AddRolePermissionRequest extends request_1.Request {
  constructor(permissionId, permissionIds) {
    super();
    this.permissionId = permissionId;
    this.permissionIds = permissionIds;
  }
  isValid() {
    if (this.permissionId === '') {
      return false;
    }
    if (this.permissionIds.length == 0) {
      return false;
    }
    return true;
  }
}
exports.AddRolePermissionRequest = AddRolePermissionRequest;