"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationStoreKeys = void 0;
class AuthenticationStoreKeys {}
exports.AuthenticationStoreKeys = AuthenticationStoreKeys;
// Name of the collection where data will be stored
AuthenticationStoreKeys.collection = 'authentication';
// Name of the keys holding the token data
AuthenticationStoreKeys.accessToken = 'access_token';
AuthenticationStoreKeys.refreshToken = 'refresh_token';
AuthenticationStoreKeys.idToken = 'id_token';
// Name of the keys holding the user data
AuthenticationStoreKeys.userId = 'user_id';
AuthenticationStoreKeys.phoneNumber = 'phone_number';
AuthenticationStoreKeys.iss = 'iss';
AuthenticationStoreKeys.email = 'email';
AuthenticationStoreKeys.emailVerified = 'email_verified';
AuthenticationStoreKeys.username = 'username';
AuthenticationStoreKeys.authTime = 'auth_time';
AuthenticationStoreKeys.authExpires = 'auth_expires';