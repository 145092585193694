"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentInitiateRequest = void 0;
const request_1 = require("../request/request");
class PaymentInitiateRequest extends request_1.Request {
  constructor(paymentProviderId, amount, currency, paymentMethodType, countryCode, userLocale, platform, cardHolderName, cardNumber, expiryMonth, expiryYear, securityCode, returnUrl, paymentTransactionId, browserInfo, billingAddress, shopperIP, shopperEmail) {
    super();
    this.paymentProviderId = paymentProviderId;
    this.amount = amount;
    this.currency = currency;
    this.paymentMethodType = paymentMethodType;
    this.countryCode = countryCode;
    this.userLocale = userLocale;
    this.platform = platform;
    this.cardHolderName = cardHolderName;
    this.cardNumber = cardNumber;
    this.expiryMonth = expiryMonth;
    this.expiryYear = expiryYear;
    this.securityCode = securityCode;
    this.returnUrl = returnUrl;
    this.paymentTransactionId = paymentTransactionId;
    this.browserInfo = browserInfo;
    this.billingAddress = billingAddress;
    this.shopperIP = shopperIP;
    this.shopperEmail = shopperEmail;
  }
  isValid() {
    if (this.paymentProviderId === '') {
      return false;
    }
    if (this.amount < 0) {
      return false;
    }
    if (this.currency === '') {
      return false;
    }
    if (this.paymentMethodType === '') {
      return false;
    }
    if (this.countryCode === '') {
      return false;
    }
    if (this.userLocale === '') {
      return false;
    }
    if (this.cardHolderName === '') {
      return false;
    }
    if (this.cardNumber === '') {
      return false;
    }
    if (this.expiryMonth === '') {
      return false;
    }
    if (this.expiryYear === '') {
      return false;
    }
    if (this.securityCode === '') {
      return false;
    }
    if (this.returnUrl === '') {
      return false;
    }
    if (this.paymentTransactionId === '') {
      return false;
    }
    return true;
  }
}
exports.PaymentInitiateRequest = PaymentInitiateRequest;