"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserManager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class UserManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  create(userCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserCreateResponse.');
      }
      return yield this.client.create(userCreateRequest);
    });
  }
  update(userUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userUpdateRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserUpdateRequest.');
      }
      this.addAuth();
      return yield this.client.update(userUpdateRequest);
    });
  }
  verify(userVerifyRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userVerifyRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserVerifyRequest.');
      }
      return yield this.client.verify(userVerifyRequest);
    });
  }
  delete(userDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userDeleteRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserDeleteRequests.');
      }
      this.addAuth();
      return yield this.client.delete(userDeleteRequest);
    });
  }
  resetPassword(userResetPasswordRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userResetPasswordRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserResetPasswordRequest.');
      }
      return yield this.client.resetPassword(userResetPasswordRequest);
    });
  }
  setPassword(userSetPasswordRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userSetPasswordRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserSetPasswordRequest.');
      }
      return yield this.client.setPassword(userSetPasswordRequest);
    });
  }
  changePassword(userChangePasswordRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userChangePasswordRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserChangePasswordRequest.');
      }
      this.addAuth();
      return yield this.client.changePassword(userChangePasswordRequest);
    });
  }
  addUserRole(userAddRoleRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userAddRoleRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in userAddRoleRequest.');
      }
      this.addAuth();
      return yield this.client.addUserRole(userAddRoleRequest);
    });
  }
  updateUserRole(userUpdateRoleRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userUpdateRoleRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in userUpdateRoleRequest.');
      }
      this.addAuth();
      return yield this.client.updateUserRole(userUpdateRoleRequest);
    });
  }
  deleteUserRole(userDeleteRoleRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userDeleteRoleRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in userDeleteRoleRequest.');
      }
      this.addAuth();
      return yield this.client.deleteUserRole(userDeleteRoleRequest);
    });
  }
  list(userListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userListRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in userListRequest.');
      }
      this.addAuth();
      return yield this.client.list(userListRequest);
    });
  }
  addAuth() {
    const authToken = this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
    if (authToken === null) throw new error_1.UnauthorizedException(UserManager.name, 'Operation requires being authenticated.');
    this.client.httpClient.addAuthentication(authToken);
  }
  createClientApplication(clientApplicationCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!clientApplicationCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in ClientApplicationCreateResponse.');
      }
      return yield this.client.createClientApplication(clientApplicationCreateRequest);
    });
  }
  deleteClientApplication(clientApplicationDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!clientApplicationDeleteRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in ClientApplicationDeleteRequests.');
      }
      this.addAuth();
      return yield this.client.deleteClientApplication(clientApplicationDeleteRequest);
    });
  }
  getClientApplication(clientApplicationGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!clientApplicationGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in ClientApplicationGetRequest.');
      }
      this.addAuth();
      return yield this.client.getClientApplication(clientApplicationGetRequest);
    });
  }
  listClientApplication(clientApplicationListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!clientApplicationListRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in ClientApplicationListRequest.');
      }
      this.addAuth();
      return yield this.client.listClientApplication(clientApplicationListRequest);
    });
  }
  createBulkUsers(userBulkCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userBulkCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserBulkCreateResponse.');
      }
      this.addAuth();
      return yield this.client.createBulkUsers(userBulkCreateRequest);
    });
  }
  resendVerification(userResendVerificationRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userResendVerificationRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserResendVerificationRequest.');
      }
      return yield this.client.resendVerification(userResendVerificationRequest);
    });
  }
  adminCreate(userAdminCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userAdminCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserAdminCreateRequest.');
      }
      this.addAuth();
      return yield this.client.adminCreate(userAdminCreateRequest);
    });
  }
  analyzeBulkUsers(userBulkAnalyzeRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userBulkAnalyzeRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserBulkAnalyzeResponse.');
      }
      this.addAuth();
      return yield this.client.analyzeBulkUsers(userBulkAnalyzeRequest);
    });
  }
  fetchUsers(userFetchRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!userFetchRequest.isValid()) {
        throw new error_1.InvalidParameterException(UserManager.name, 'Missing one or more of the required parameters in UserFetchResponse.');
      }
      this.addAuth();
      return yield this.client.fetchUsers(userFetchRequest);
    });
  }
}
exports.UserManager = UserManager;