"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileManagementHttpClient = void 0;
class ProfileManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  create(profileCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/profiles`;
      const response = yield this.httpClient.post(url, {
        type: profileCreateRequest.type,
        profile: profileCreateRequest.profile,
        userId: profileCreateRequest.userId
      });
      return response;
    });
  }
  update(ProfileUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/profiles/${ProfileUpdateRequest.userId}`;
      const response = yield this.httpClient.put(url, {
        type: ProfileUpdateRequest.type,
        profile: ProfileUpdateRequest.profile
      });
      return response;
    });
  }
  delete(ProfileDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/profiles/${ProfileDeleteRequest.userId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  get(ProfileGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/profiles/${ProfileGetRequest.userId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  list(profileListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/profiles`;
      const response = yield this.httpClient.get(url, profileListRequest.filter);
      return response;
    });
  }
  bulk(profileBulkCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/profiles/bulk`;
      const response = yield this.httpClient.post(url, {
        profiles: profileBulkCreateRequest.profiles
      });
      return response;
    });
  }
}
exports.ProfileManagementHttpClient = ProfileManagementHttpClient;