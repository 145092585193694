"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserDeleteRoleRequest = void 0;
const request_1 = require("../request/request");
class UserDeleteRoleRequest extends request_1.Request {
  constructor(userId, roleIds) {
    super();
    this.roleIds = roleIds;
    this.userId = userId;
  }
  isValid() {
    if (this.roleIds.length == 0) {
      return false;
    }
    if (this.userId === '') {
      return false;
    }
    return true;
  }
}
exports.UserDeleteRoleRequest = UserDeleteRoleRequest;