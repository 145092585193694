"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserChangePasswordRequest = void 0;
const request_1 = require("../request/request");
class UserChangePasswordRequest extends request_1.Request {
  constructor(userId, oldPassword, newPassword) {
    super();
    this.userId = userId;
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
  }
  isValid() {
    if (this.userId === '') {
      return false;
    }
    if (this.oldPassword === '') {
      return false;
    }
    if (this.newPassword === '') {
      return false;
    }
    return true;
  }
}
exports.UserChangePasswordRequest = UserChangePasswordRequest;