"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateManager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class TemplateManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  create(templateCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!templateCreateRequest.isValid()) {
        throw new error_1.InvalidParameterException(TemplateManager.name, 'Missing one or more of the required parameters in templateCreateRequest.');
      }
      this.addAuth();
      return yield this.client.create(templateCreateRequest);
    });
  }
  update(templateUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!templateUpdateRequest.isValid()) {
        throw new error_1.InvalidParameterException(TemplateManager.name, 'Missing one or more of the required parameters in templateUpdateRequest.');
      }
      this.addAuth();
      return yield this.client.update(templateUpdateRequest);
    });
  }
  delete(templateDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!templateDeleteRequest.isValid()) {
        throw new error_1.InvalidParameterException(TemplateManager.name, 'Missing one or more of the required parameters in TemplateDeleteRequest.');
      }
      this.addAuth();
      return yield this.client.delete(templateDeleteRequest);
    });
  }
  get(templateGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!templateGetRequest.isValid()) {
        throw new error_1.InvalidParameterException(TemplateManager.name, 'Missing one or more of the required parameters in TemplateGetRequest.');
      }
      this.addAuth();
      return yield this.client.get(templateGetRequest);
    });
  }
  list(templateListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      this.addAuth();
      return yield this.client.list(templateListRequest);
    });
  }
  addAuth() {
    const authToken = this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
    if (authToken === null) throw new error_1.UnauthorizedException(TemplateManager.name, 'Operation requires being authenticated.');
    this.client.httpClient.addAuthentication(authToken);
  }
}
exports.TemplateManager = TemplateManager;