"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigurationData = void 0;
exports.ConfigurationData = {
  dev: {
    applicationManagementServiceUrl: 'https://application-management.service.dev.cloud1.ws.continental-industry.com',
    authenticationManagementServiceUrl: 'https://authentication-management.service.dev.cloud1.ws.continental-industry.com',
    userManagementServiceUrl: 'https://user-management.service.dev.cloud1.ws.continental-industry.com',
    profileManagementServiceUrl: 'https://profile-management.service.dev.cloud1.ws.continental-industry.com',
    emailManagementServiceUrl: 'https://email-management.service.dev.cloud1.ws.continental-industry.com',
    imageManagementServiceUrl: 'https://image-management.service.dev.cloud1.ws.continental-industry.com',
    roleManagementServiceUrl: 'https://role-management.service.dev.cloud1.ws.continental-industry.com',
    videoManagementServiceUrl: 'https://video-management.service.dev.cloud1.ws.continental-industry.com',
    pushNotificationManagementServiceUrl: 'https://push-notification.service.dev.cloud1.ws.continental-industry.com',
    fileManagementServiceUrl: 'https://file-management.service.dev.cloud1.ws.continental-industry.com',
    legalDocumentServiceUrl: 'https://legal-document-management.service.dev.cloud1.ws.continental-industry.com',
    consentOptionManagementServiceUrl: 'https://consent-option-management.service.dev.cloud1.ws.continental-industry.com',
    consentUserManagementServiceUrl: 'https://consent-user-management.service.dev.cloud1.ws.continental-industry.com',
    paymentManagementServiceUrl: 'https://payment-management.service.dev.cloud1.ws.continental-industry.com',
    tokenManagementServiceUrl: 'https://token-management.service.dev.cloud1.ws.continental-industry.com',
    templateManagementServiceUrl: 'https://template-management.service.dev.cloud1.ws.continental-industry.com',
    communicationManagementServiceUrl: 'https://communication-management.service.dev.cloud1.ws.continental-industry.com',
    videoRenderingManagementServiceUrl: 'https://video-rendering-management.service.dev.cloud1.ws.continental-industry.com',
    videoUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    imageUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    fileUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    }
  },
  stage: {
    applicationManagementServiceUrl: 'https://application-management.service.stage.cloud1.ws.continental-industry.com',
    authenticationManagementServiceUrl: 'https://authentication-management.service.stage.cloud1.ws.continental-industry.com',
    userManagementServiceUrl: 'https://user-management.service.stage.cloud1.ws.continental-industry.com',
    profileManagementServiceUrl: 'https://profile-management.service.stage.cloud1.ws.continental-industry.com',
    emailManagementServiceUrl: 'https://email-management.service.stage.cloud1.ws.continental-industry.com',
    imageManagementServiceUrl: 'https://image-management.service.stage.cloud1.ws.continental-industry.com',
    roleManagementServiceUrl: 'https://role-management.service.stage.cloud1.ws.continental-industry.com',
    videoManagementServiceUrl: 'https://video-management.service.stage.cloud1.ws.continental-industry.com',
    pushNotificationManagementServiceUrl: 'https://push-notification.service.stage.cloud1.ws.continental-industry.com',
    fileManagementServiceUrl: 'https://file-management.service.stage.cloud1.ws.continental-industry.com',
    legalDocumentServiceUrl: 'https://legal-document-management.service.stage.cloud1.ws.continental-industry.com',
    consentOptionManagementServiceUrl: 'https://consent-option-management.service.stage.cloud1.ws.continental-industry.com',
    consentUserManagementServiceUrl: 'https://consent-user-management.service.stage.cloud1.ws.continental-industry.com',
    paymentManagementServiceUrl: 'https://payment-management.service.stage.cloud1.ws.continental-industry.com',
    tokenManagementServiceUrl: 'https://token-management.service.stage.cloud1.ws.continental-industry.com',
    templateManagementServiceUrl: 'https://template-management.service.stage.cloud1.ws.continental-industry.com',
    communicationManagementServiceUrl: 'https://communication-management.service.stage.cloud1.ws.continental-industry.com',
    videoRenderingManagementServiceUrl: 'https://video-rendering-management.service.stage.cloud1.ws.continental-industry.com',
    videoUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    imageUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    fileUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    }
  },
  sandbox: {
    applicationManagementServiceUrl: 'https://application-management.service.sandbox.cloud1.ws.continental-industry.com',
    authenticationManagementServiceUrl: 'https://authentication-management.service.sandbox.cloud1.ws.continental-industry.com',
    userManagementServiceUrl: 'https://user-management.service.sandbox.cloud1.ws.continental-industry.com',
    profileManagementServiceUrl: 'https://profile-management.service.sandbox.cloud1.ws.continental-industry.com',
    emailManagementServiceUrl: 'https://email-management.service.sandbox.cloud1.ws.continental-industry.com',
    imageManagementServiceUrl: 'https://image-management.service.sandbox.cloud1.ws.continental-industry.com',
    roleManagementServiceUrl: 'https://role-management.service.sandbox.cloud1.ws.continental-industry.com',
    videoManagementServiceUrl: 'https://video-management.service.sandbox.cloud1.ws.continental-industry.com',
    pushNotificationManagementServiceUrl: 'https://push-notification.service.sandbox.cloud1.ws.continental-industry.com',
    fileManagementServiceUrl: 'https://file-management.service.sandbox.cloud1.ws.continental-industry.com',
    legalDocumentServiceUrl: 'https://legal-document-management.service.sandbox.cloud1.ws.continental-industry.com',
    consentOptionManagementServiceUrl: 'https://consent-option-management.service.sandbox.cloud1.ws.continental-industry.com',
    consentUserManagementServiceUrl: 'https://consent-user-management.service.sandbox.cloud1.ws.continental-industry.com',
    paymentManagementServiceUrl: 'https://payment-management.service.sandbox.cloud1.ws.continental-industry.com',
    tokenManagementServiceUrl: 'https://token-management.service.sandbox.cloud1.ws.continental-industry.com',
    templateManagementServiceUrl: 'https://template-management.service.sandbox.cloud1.ws.continental-industry.com',
    communicationManagementServiceUrl: 'https://communication-management.service.sandbox.cloud1.ws.continental-industry.com',
    videoRenderingManagementServiceUrl: 'https://video-rendering-management.service.sandbox.cloud1.ws.continental-industry.com',
    videoUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    imageUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    fileUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    }
  },
  prod: {
    applicationManagementServiceUrl: 'https://application-management.service.cloud1.ws.continental-industry.com',
    authenticationManagementServiceUrl: 'https://authentication-management.service.cloud1.ws.continental-industry.com',
    userManagementServiceUrl: 'https://user-management.service.cloud1.ws.continental-industry.com',
    profileManagementServiceUrl: 'https://profile-management.service.cloud1.ws.continental-industry.com',
    emailManagementServiceUrl: 'https://email-management.service.cloud1.ws.continental-industry.com',
    imageManagementServiceUrl: 'https://image-management.service.cloud1.ws.continental-industry.com',
    roleManagementServiceUrl: 'https://role-management.service.cloud1.ws.continental-industry.com',
    videoManagementServiceUrl: 'https://video-management.service.cloud1.ws.continental-industry.com',
    pushNotificationManagementServiceUrl: 'https://push-notification.service.cloud1.ws.continental-industry.com',
    fileManagementServiceUrl: 'https://file-management.service.cloud1.ws.continental-industry.com',
    legalDocumentServiceUrl: 'https://legal-document-management.service.cloud1.ws.continental-industry.com',
    consentOptionManagementServiceUrl: 'https://consent-option-management.service.cloud1.ws.continental-industry.com',
    consentUserManagementServiceUrl: 'https://consent-user-management.service.cloud1.ws.continental-industry.com',
    paymentManagementServiceUrl: 'https://payment-management.service.cloud1.ws.continental-industry.com',
    tokenManagementServiceUrl: 'https://token-management.service.cloud1.ws.continental-industry.com',
    templateManagementServiceUrl: 'https://template-management.service.cloud1.ws.continental-industry.com',
    communicationManagementServiceUrl: 'https://communication-management.service.cloud1.ws.continental-industry.com',
    videoRenderingManagementServiceUrl: 'https://video-rendering-management.service.cloud1.ws.continental-industry.com',
    videoUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    imageUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    },
    fileUploadConfig: {
      chunkSize: 5 * 1024 * 1024,
      retry: {
        initialDelay: 3,
        maxAttempt: 3
      }
    }
  }
};