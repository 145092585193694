"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageManagementHttpClient = void 0;
const error_1 = require("../errors/error");
const httpUpload_1 = require("../http/httpUpload");
class ImageManagementHttpClient {
  constructor(http, baseUrl, uploadParams) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.httpUploadClient = null;
    this.tempCredentials = {};
    this.uploadParams = uploadParams;
  }
  // Upload image 
  upload(imageUploadRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        if (this.httpUploadClient) {
          throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, "Upload already in progress");
        }
        let uploadConfig = {
          credentialUrl: `${this.baseUrl}/images`,
          credentialBody: {
            fileName: imageUploadRequest.file.name,
            public: imageUploadRequest.public
          },
          file: imageUploadRequest.file,
          params: this.uploadParams
        };
        this.httpUploadClient = new httpUpload_1.HttpUploadClient(this.httpClient, uploadConfig);
        let uploadResponse = yield this.httpUploadClient.upload();
        this.httpUploadClient = null;
        let response = {
          imageId: uploadResponse.imageId,
          key: uploadResponse.key,
          url: uploadResponse.url,
          createdDate: uploadResponse.createdDate,
          updatedDate: uploadResponse.updatedDate
        };
        return response;
      } catch (error) {
        this.httpUploadClient = null;
        throw error;
      }
    });
  }
  discardUpload() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.httpUploadClient) {
        throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, "No upload is in process");
      }
      const response = yield this.httpUploadClient.discardUpload();
      this.httpUploadClient = null;
      return response;
    });
  }
  // Get image 
  get(imageGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/images/${imageGetRequest.imageId}`;
      const response = yield this.httpClient.get(url);
      return {
        imageId: response.imageId,
        key: response.key,
        url: response.url,
        createdDate: response.createdDate,
        updatedDate: response.updatedDate
      };
    });
  }
  // List images
  list(imageListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/images`;
      const response = yield this.httpClient.get(url, imageListRequest.filter);
      return response;
    });
  }
}
exports.ImageManagementHttpClient = ImageManagementHttpClient;