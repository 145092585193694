"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoRenderingManagementHttpClient = void 0;
class VideoRenderingManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.httpUploadClient = null;
    this.baseUrl = baseUrl;
    this.tempCredentials = {};
  }
  initiateVideoEdit(initiateEditRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits`;
      const response = yield this.httpClient.post(url, {
        videoId: initiateEditRequest.videoId,
        originalVideoDuration: initiateEditRequest.originalVideoDuration,
        dimension: initiateEditRequest.dimension,
        name: initiateEditRequest.name
      });
      return response;
    });
  }
  updateVideoEdit(updateEditRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${updateEditRequest.editId}`;
      const response = yield this.httpClient.patch(url, {
        name: updateEditRequest.name
      });
      return response;
    });
  }
  deleteVideoEdit(deleteEditRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${deleteEditRequest.editId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  getVideoEdit(getEditRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${getEditRequest.editId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  finishVideoEdit(finishEditRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${finishEditRequest.editId}`;
      const response = yield this.httpClient.put(url, {
        name: finishEditRequest.name
      });
      return response;
    });
  }
  createEditInstruction(createInstructionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${createInstructionRequest.editId}/instructions`;
      const response = yield this.httpClient.post(url, createInstructionRequest.instruction);
      return response;
    });
  }
  createBulkEditInstruction(createInstructionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${createInstructionRequest.editId}/bulk-instructions`;
      const response = yield this.httpClient.post(url, {
        instructions: createInstructionRequest.instructions
      });
      return response;
    });
  }
  updateEditInstruction(updateInstructionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${updateInstructionRequest.editId}/instructions/${updateInstructionRequest.instructionId}`;
      const response = yield this.httpClient.put(url, updateInstructionRequest.instruction);
      return response;
    });
  }
  deleteEditInstruction(deleteInstructionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${deleteInstructionRequest.editId}/instructions/${deleteInstructionRequest.instructionId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  listEditInstruction(listInstructionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/edits/${listInstructionRequest.editId}/instructions`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
}
exports.VideoRenderingManagementHttpClient = VideoRenderingManagementHttpClient;