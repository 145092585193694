"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageUploadRequest = void 0;
const request_1 = require("../request/request");
// Upload Image request
class ImageUploadRequest extends request_1.Request {
  constructor(file, publicImage) {
    super();
    this.file = file;
    this.public = publicImage;
  }
  isValid() {
    if (!this.file) {
      return false;
    }
    let allowedExt = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'image/blob'];
    if (!allowedExt.includes(this.file.type)) {
      return false;
    }
    return true;
  }
}
exports.ImageUploadRequest = ImageUploadRequest;