"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotifyRequest = void 0;
const request_1 = require("../request/request");
// Register request
class NotifyRequest extends request_1.Request {
  constructor(deviceId, payload) {
    super();
    this.deviceId = deviceId, this.payload = payload;
  }
  isValid() {
    if (this.deviceId === "") {
      return false;
    }
    if (this.payload === "") {
      return false;
    }
    return true;
  }
}
exports.NotifyRequest = NotifyRequest;