"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoManager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class VideoManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  // Upload video
  upload(videoUploadRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!videoUploadRequest.isValid()) {
        throw new error_1.InvalidParameterException(VideoManager.name, 'Invalid required parameters in request.');
      }
      this.addAuth();
      return yield this.client.upload(videoUploadRequest);
    });
  }
  // Discard video
  discard() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.client.discardUpload();
      return true;
    });
  }
  // Delete video
  delete(videoDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!videoDeleteRequest.isValid()) {
        throw new error_1.InvalidParameterException(VideoManager.name, 'Invalid required parameters in request.');
      }
      this.addAuth();
      return yield this.client.delete(videoDeleteRequest);
    });
  }
  getVideoDetails(getVideoRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!getVideoRequest.isValid()) {
        throw new error_1.InvalidParameterException(VideoManager.name, 'Missing one or more of the required parameters in request.');
      }
      this.addAuth();
      return yield this.client.getVideoDetails(getVideoRequest);
    });
  }
  addAuth() {
    const authToken = this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
    if (authToken === null) throw new error_1.UnauthorizedException(VideoManager.name, 'Operation requires being authenticated.');
    this.client.httpClient.addAuthentication(authToken);
  }
}
exports.VideoManager = VideoManager;