"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TokenManager = void 0;
const error_1 = require("../errors/error");
const manager_1 = require("../manager/manager");
class TokenManager extends manager_1.Manager {
  constructor(store, client, config) {
    super(store, client.httpClient);
    this.store = store;
    this.config = config;
    this.client = client;
  }
  validate(TokenValidateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!TokenValidateRequest.isValid()) {
        throw new error_1.InvalidParameterException(TokenManager.name, 'Missing one or more of the required parameters in TokenValidateRequest.');
      }
      return yield this.client.validate(TokenValidateRequest);
    });
  }
}
exports.TokenManager = TokenManager;