"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PermissionCreateRequest = void 0;
const request_1 = require("../request/request");
// create permission request
class PermissionCreateRequest extends request_1.Request {
  constructor(permissionName, url, method) {
    super();
    this.permissionName = permissionName;
    this.url = url;
    this.method = method;
  }
  isValid() {
    if (this.permissionName === '') {
      return false;
    }
    if (this.url === '') {
      return false;
    }
    if (this.method === '') {
      return false;
    }
    return true;
  }
}
exports.PermissionCreateRequest = PermissionCreateRequest;