"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommunicationManagementHttpClient = void 0;
class CommunicationManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  create(communicationCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/events`;
      const response = yield this.httpClient.post(url, {
        eventName: communicationCreateRequest.eventName,
        channel: communicationCreateRequest.channel,
        payload: communicationCreateRequest.payload,
        targetId: communicationCreateRequest.targetId,
        eventType: communicationCreateRequest.eventType,
        messageId: communicationCreateRequest.messageId,
        eventCategory: communicationCreateRequest.eventCategory
      });
      return response;
    });
  }
  listEvent(communicationListEventRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/events`;
      const response = yield this.httpClient.get(url, communicationListEventRequest.filter);
      return response;
    });
  }
  listChannel(communicationListChannelRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/channels`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  openConnection(communicationOpenConnectionRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/connections`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
}
exports.CommunicationManagementHttpClient = CommunicationManagementHttpClient;