"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentUserListRequest = void 0;
const request_1 = require("../request/request");
class ConsentUserListRequest extends request_1.Request {
  constructor() {
    super();
  }
  isValid() {
    return true;
  }
}
exports.ConsentUserListRequest = ConsentUserListRequest;