"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentListRequest = void 0;
const request_1 = require("../request/request");
class DocumentListRequest extends request_1.Request {
  constructor(request) {
    super();
    this.limit = request === null || request === void 0 ? void 0 : request.limit;
    this.next = request === null || request === void 0 ? void 0 : request.next;
    this.prev = request === null || request === void 0 ? void 0 : request.prev;
  }
  isValid() {
    if (this.limit) {
      if (typeof this.limit !== 'number') {
        return false;
      }
    }
    return true;
  }
}
exports.DocumentListRequest = DocumentListRequest;