"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceListRequest = void 0;
const request_1 = require("../request/request");
// Register request
class DeviceListRequest extends request_1.Request {
  constructor() {
    super();
  }
  isValid() {
    return true;
  }
}
exports.DeviceListRequest = DeviceListRequest;