"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileBulkCreateRequest = void 0;
const request_1 = require("../request/request");
class ProfileBulkCreateRequest extends request_1.Request {
  constructor(profiles) {
    super();
    this.profiles = profiles;
  }
  isValid() {
    if (this.profiles.length == 0) {
      return false;
    }
    return true;
  }
}
exports.ProfileBulkCreateRequest = ProfileBulkCreateRequest;