"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilesCreateRequest = void 0;
const request_1 = require("../request/request");
// Create Files request
class FilesCreateRequest extends request_1.Request {
  constructor(files, publicFile, path, owner) {
    super();
    this.files = files;
    this.public = publicFile;
    this.path = path;
    this.owner = owner; //quick fix because, cmn authorizer function logic is not working as expected
  }
  isValid() {
    if (this.files.length == 0) {
      return false;
    }
    let allowedExt = ['file/xls', 'file/pdf', 'file/txt', 'file/doc', 'file/docx', 'file/xlsx', 'file/ppt', 'file/csv', 'file/json', 'file/zip', 'file/stp', 'file/bin'];
    this.files.forEach(file => {
      if (!allowedExt.includes(file.type)) {
        return false;
      }
    });
    if (this.path !== undefined && this.path.trim() === '') {
      return false;
    }
    return true;
  }
}
exports.FilesCreateRequest = FilesCreateRequest;