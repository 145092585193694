"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VideoUploadRequest = void 0;
const request_1 = require("../request/request");
// Upload Video request
class VideoUploadRequest extends request_1.Request {
  constructor(file, publicVideo) {
    super();
    this.file = file;
    this.public = publicVideo;
  }
  isValid() {
    if (!this.file) {
      return false;
    }
    let allowedExt = ['video/mp4', 'audio/aac', 'audio/x-ms-wma', 'audio/wav', 'audio/webm', 'audio/mpeg', 'audio/mpeg3', 'audio/x-mpeg-3', 'audio/mp4'];
    if (!allowedExt.includes(this.file.type)) {
      return false;
    }
    return true;
  }
}
exports.VideoUploadRequest = VideoUploadRequest;