"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetVideoRequest = void 0;
const request_1 = require("../request/request");
class GetVideoRequest extends request_1.Request {
  constructor(videoId) {
    super();
    this.videoId = videoId;
  }
  isValid() {
    if (!this.videoId) {
      return false;
    }
    return true;
  }
}
exports.GetVideoRequest = GetVideoRequest;