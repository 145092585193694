"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpRequestError = void 0;
/// This Exception gets thrown when a service returns an error or Axios has a
/// problem.
class HttpRequestError extends Error {
  constructor(thrower, statusCode, serviceMessage, errorCode, errorMessage) {
    super(thrower);
    this.thrower = thrower;
    this.statusCode = statusCode;
    this.serviceMessage = serviceMessage;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}
exports.HttpRequestError = HttpRequestError;