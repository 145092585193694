"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Manager = void 0;
const cmn_ibd_store_keys_typescript_sdk_1 = require("@ct-ibd/cmn-ibd-store-keys-typescript-sdk");
const error_1 = require("../errors/error");
class Manager {
  constructor(store, http) {
    this.store = store;
    this.http = http;
  }
  addAuthToClient() {
    return __awaiter(this, void 0, void 0, function* () {
      const authToken = yield this.store.get(cmn_ibd_store_keys_typescript_sdk_1.AuthenticationStoreKeys.accessToken);
      if (authToken == '') {
        throw new error_1.UnauthorizedException(Manager.name, 'Logout requires being authenticated.');
      }
      this.http.addAuthentication(authToken);
    });
  }
  removeAuthFromClient() {
    this.http.removeAuthentication();
  }
}
exports.Manager = Manager;