"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsentOptionManagementHttpClient = void 0;
class ConsentOptionManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  update(consentOptionUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/options/${consentOptionUpdateRequest.optionId}`;
      const response = yield this.httpClient.put(url, {
        optionName: consentOptionUpdateRequest.optionName,
        content: consentOptionUpdateRequest.content,
        categories: consentOptionUpdateRequest.categories,
        localesAvailable: consentOptionUpdateRequest.localesAvailable,
        defaultLocale: consentOptionUpdateRequest.defaultLocale,
        isSystemCritical: consentOptionUpdateRequest.isSystemCritical,
        version: consentOptionUpdateRequest.version
      });
      return response;
    });
  }
  create(consentOptionCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/options`;
      const response = yield this.httpClient.post(url, {
        optionName: consentOptionCreateRequest.optionName,
        content: consentOptionCreateRequest.content,
        categories: consentOptionCreateRequest.categories,
        localesAvailable: consentOptionCreateRequest.localesAvailable,
        defaultLocale: consentOptionCreateRequest.defaultLocale,
        isSystemCritical: consentOptionCreateRequest.isSystemCritical
      });
      return response;
    });
  }
  delete(consentOptionDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/options`;
      const response = yield this.httpClient.delete(`${url}/${consentOptionDeleteRequest.optionId}`);
      return response;
    });
  }
  get(consentOptionGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/options/${consentOptionGetRequest.optionId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  list(consentOptionListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/options`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
}
exports.ConsentOptionManagementHttpClient = ConsentOptionManagementHttpClient;