"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmailSendRequest = void 0;
const request_1 = require("../request/request");
// Email send request
class EmailSendRequest extends request_1.Request {
  constructor(recipient, sender, subject, content, attachments, contentType) {
    super();
    this.recipient = recipient;
    this.sender = sender;
    this.subject = subject;
    this.content = content;
    this.contentType = contentType ? contentType : 'html';
    this.attachments = attachments ? attachments : [];
  }
  isValid() {
    if (this.recipient === '' || Array.isArray(this.recipient) && !this.recipient.length) {
      return false;
    }
    if (this.sender === '') {
      return false;
    }
    if (this.subject === '') {
      return false;
    }
    if (this.content === '') {
      return false;
    }
    return true;
  }
}
exports.EmailSendRequest = EmailSendRequest;