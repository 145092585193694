"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileListRequest = void 0;
const request_1 = require("../request/request");
// List Images
class FileListRequest extends request_1.Request {
  constructor(filter) {
    super();
    this.filter = filter ? filter : undefined;
  }
  isValid() {
    return true;
  }
}
exports.FileListRequest = FileListRequest;