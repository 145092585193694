import { AuthenticatedUserResponse } from '../../models/user';
import { Injectable } from '@angular/core';
import {
  Ibd,
  Configuration,
  ConfigurationDataReturnType,
  UserChangePasswordRequest,
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../storage/storage.service';
import { from, Subject, Observable, of } from 'rxjs';
import { concatMap, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserChangePasswordResponse } from '@ct-ibd/cmn-ibd-typescript-sdk/dist/src/user/user_change_password_response';

@Injectable()
export class IbdSdkService {
  ibd: Ibd;
  config: ConfigurationDataReturnType;

  constructor(public store: StorageService, router: Router) {
    this.config = new Configuration(
      environment.cmsSettings.appClientId,
      environment.cmsSettings.envName
    ).load();
    this.ibd = new Ibd(this.store, this.config);
  }

  loginCheck(): Observable<AuthenticatedUserResponse | boolean | null> {
    if (this.ibd.authenticationManager.isAuthenticationExpired()) {
      return from(this.ibd.authenticationManager.refreshAuthentication()).pipe(
        takeWhile(
          (responseData: AuthenticatedUserResponse | null) =>
            responseData != null
        )
      );
    }
    return of(false);
  }

  async authToken() {
    const authenticatedUser = await this.ibd.authenticationManager
      .loginCheck()
      .then((resp) => {
        return resp;
      });
    if (authenticatedUser) {
      return authenticatedUser.accessToken;
    }
    return '';
  }

  userChangePassword(
    userId: string,
    oldPassword: string,
    newPassword: string
  ): Observable<UserChangePasswordResponse | null> {
    const changePasswordRequest = new UserChangePasswordRequest(
      userId,
      oldPassword,
      newPassword
    );
    return from(this.ibd.userManager.changePassword(changePasswordRequest));
  }
}
