"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserCreateRequest = void 0;
const request_1 = require("../request/request");
class UserCreateRequest extends request_1.Request {
  constructor(email, username, phoneNumber, password, locale, templatePayload) {
    super();
    this.email = email;
    this.username = username;
    this.phoneNumber = phoneNumber;
    this.password = password;
    this.locale = locale;
    this.templatePayload = templatePayload;
  }
  isValid() {
    if (this.email === '') {
      return false;
    }
    if (this.username === '') {
      return false;
    }
    if (this.phoneNumber === '') {
      return false;
    }
    if (this.password === '') {
      return false;
    }
    if (this.locale === '') {
      return false;
    }
    return true;
  }
}
exports.UserCreateRequest = UserCreateRequest;