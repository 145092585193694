"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileListRequest = void 0;
class ProfileListRequest {
  constructor(filter) {
    this.filter = filter ? filter : undefined;
  }
}
exports.ProfileListRequest = ProfileListRequest;