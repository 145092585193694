"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileUpdateRequest = void 0;
const request_1 = require("../request/request");
class ProfileUpdateRequest extends request_1.Request {
  constructor(profile, userId, type) {
    super();
    this.profile = profile;
    this.userId = userId;
    this.type = type;
  }
  isValid() {
    if (Object.keys(this.profile).length == 0) {
      return false;
    }
    if (this.userId === '') {
      return false;
    }
    if (this.type === '') {
      return false;
    }
    return true;
  }
}
exports.ProfileUpdateRequest = ProfileUpdateRequest;