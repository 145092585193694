"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileManagementHttpClient = void 0;
const error_1 = require("../errors/error");
const httpUpload_1 = require("../http/httpUpload");
class FileManagementHttpClient {
  constructor(http, baseUrl, createParams) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
    this.httpUploadClient = null;
    this.tempCredentials = {};
    this.createParams = createParams;
  }
  // create file 
  create(FileCreateReplaceRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.httpUploadClient) {
        throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, "create already in progress");
      }
      let uploadConfig = {
        credentialUrl: `${this.baseUrl}/files`,
        credentialBody: {
          name: FileCreateReplaceRequest.path !== undefined ? `${FileCreateReplaceRequest.path.trim()}/${FileCreateReplaceRequest.file.name}` : FileCreateReplaceRequest.file.name,
          public: FileCreateReplaceRequest.public,
          replace: FileCreateReplaceRequest.replace,
          owner: FileCreateReplaceRequest.owner //quick fix because, cmn authorizer function logic is not working as expected
        },
        file: FileCreateReplaceRequest.file,
        params: this.createParams
      };
      this.httpUploadClient = new httpUpload_1.HttpUploadClient(this.httpClient, uploadConfig);
      let uploadResponse = yield this.httpUploadClient.upload();
      let response = {
        createdDate: uploadResponse.createdDate
      };
      this.httpUploadClient = null;
      return response;
    });
  }
  discardUpload() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.httpUploadClient) {
        throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, "No create is in process");
      }
      return yield this.httpUploadClient.discardUpload();
    });
  }
  // Create folder
  createFolder(folderCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/folders`;
      const response = yield this.httpClient.post(url, {
        name: folderCreateRequest.name
      });
      return {
        id: response.folderId,
        key: response.key,
        createdDate: response.createdDate,
        updatedDate: response.updatedDate,
        url: response.url
      };
    });
  }
  // Move
  move(fileMoveRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/files/${fileMoveRequest.fileId}`;
      const response = yield this.httpClient.put(url, {
        destName: fileMoveRequest.destName,
        permissions: fileMoveRequest.permissions,
        public: fileMoveRequest.publicFile
      });
      return {
        message: response.message
      };
    });
  }
  // Delete file or folder
  delete(deleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/files/${deleteRequest.fileId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  createFiles(FilesCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.httpUploadClient) {
        throw new error_1.OperationException(httpUpload_1.HttpUploadClient.name, "create files already in progress");
      }
      let response = {
        filesCount: FilesCreateRequest.files.length,
        filesSuccessUpload: [],
        filesFailureUpload: []
      };
      for (const file of FilesCreateRequest.files) {
        let uploadConfig = {
          credentialUrl: `${this.baseUrl}/files`,
          credentialBody: {
            name: FilesCreateRequest.path !== undefined ? `${FilesCreateRequest.path.trim()}/${file.name}` : file.name,
            public: FilesCreateRequest.public,
            owner: FilesCreateRequest.owner //quick fix because, cmn authorizer function logic is not working as expected
          },
          file: file,
          params: this.createParams
        };
        this.httpUploadClient = new httpUpload_1.HttpUploadClient(this.httpClient, uploadConfig);
        let uploadResponse = yield this.httpUploadClient.upload();
        if (uploadResponse && uploadResponse.createdDate) {
          response.filesSuccessUpload.push({
            fileName: file.name,
            createdDate: uploadResponse.createdDate,
            fileId: uploadResponse.fileId,
            key: uploadResponse.key,
            url: uploadResponse.url,
            updatedDate: uploadResponse.updatedDate
          });
        } else {
          response.filesFailureUpload.push({
            fileName: file.name
          });
        }
      }
      this.httpUploadClient = null;
      return response;
    });
  }
  // Get file 
  get(fileGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/files/${fileGetRequest.fileId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  // List Files
  list(fileListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/files`;
      const response = yield this.httpClient.get(url, fileListRequest.filter);
      return response;
    });
  }
}
exports.FileManagementHttpClient = FileManagementHttpClient;