"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DocumentManagementHttpClient = void 0;
class DocumentManagementHttpClient {
  constructor(http, baseUrl) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }
  create(documentCreateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/documents`;
      const response = yield this.httpClient.post(url, {
        name: documentCreateRequest.name,
        locale: documentCreateRequest.locale,
        content: documentCreateRequest.content
      });
      return response;
    });
  }
  update(DocumentUpdateRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/documents/${DocumentUpdateRequest.documentId}`;
      const response = yield this.httpClient.put(url, {
        name: DocumentUpdateRequest.name,
        locale: DocumentUpdateRequest.locale,
        content: DocumentUpdateRequest.content,
        version: DocumentUpdateRequest.version
      });
      return response;
    });
  }
  delete(DocumentDeleteRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/documents/${DocumentDeleteRequest.documentId}`;
      const response = yield this.httpClient.delete(url);
      return response;
    });
  }
  get(DocumentGetRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/documents/${DocumentGetRequest.documentId}`;
      const response = yield this.httpClient.get(url);
      return response;
    });
  }
  list(documentListRequest) {
    return __awaiter(this, void 0, void 0, function* () {
      const url = `${this.baseUrl}/documents`;
      const params = {};
      if (documentListRequest.limit) {
        params.limit = documentListRequest.limit;
      }
      if (documentListRequest.next) {
        params.next = documentListRequest.next;
      }
      if (documentListRequest.prev) {
        params.prev = documentListRequest.prev;
      }
      const response = yield this.httpClient.get(url, params);
      return response;
    });
  }
}
exports.DocumentManagementHttpClient = DocumentManagementHttpClient;