"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileMoveRequest = void 0;
const request_1 = require("../request/request");
class FileMoveRequest extends request_1.Request {
  constructor(fileId, destName, permissions = ["r"], publicFile = true) {
    super();
    this.fileId = fileId;
    this.destName = destName;
    this.permissions = permissions;
    this.publicFile = publicFile;
  }
  isValid() {
    if (!this.fileId) {
      return false;
    }
    if (!this.destName) {
      return false;
    }
    if (!this.permissions) {
      return false;
    }
    return true;
  }
}
exports.FileMoveRequest = FileMoveRequest;